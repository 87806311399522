import React, { Component } from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import NumberFormat from "react-number-format";
import { Layout, Button, Drawer, Steps, message, Form, Row, Col, List, DatePicker, Tooltip, Modal, notification, Table, Checkbox, Select, Icon, Tag, Input, Spin, InputNumber } from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import Api from "../../api/api-handler";
import "../../static/css/add.css";
import moment from "moment";

import LayoutContent from "../../components/utility/layoutContent";
import LayoutContentWrapper from "../../components/utility/layoutContent";
import { withRouter } from 'react-router-dom'
import Sample_Qr from "../../static/images/sample_qr.png";
import { AlternateEmail } from "@material-ui/icons";
import { MOVER_STORAGE_API_URL } from "../../static/data/constants";
import axios from 'axios';
import PlacesAutocomplete, {
	geocodeByAddress,
} from 'react-places-autocomplete';
import { HmacSHA512 } from "crypto-js";
import authAction from "../../redux/auth/actions";



const { Header } = Layout;
const Search = Input.Search;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];
const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const { login } = authAction;
const API = new Api({});
let timeoutVar;
let deleteStaffId



const steps = [
	{
		title: 'Add Customer',
		content: 'Create new customer',
	},
	{
		title: 'Add Shipment',
		content: 'Create new shipment',
	},
	{
		title: 'Assign Workers',
		content: 'Assign Workers',
	},
	{
		title: 'Workers',
		content: 'Workers',
	},
	{
		title: 'Create Labels',
		content: 'Create new labels',
	},
	{
		title: 'Print QR Code',
		content: 'Print QR Code',
	},
];

const { Step } = Steps;

class Topbar extends Component {
	state = {
		isPickupDateMandatoryFlag: false,
		visible: false,
		current: 0,
		loading: false,
		contentloader: false,
		tags: [],
		tagShipment: [],
		shipmentTypeData: null,
		companyId: "",
		customerIdForShipment: null,
		storageCustomerIdForShipment: null,
		customerNameForShipment: null,
		customerLastNameForShipment: null,
		customerEmailForShipment: null,
		customerAccountIdForShipment: null,
		customerPhoneForShipment: null,
		shipmentIdForLabels: null,
		warehouseIdForLabels: null,
		shipmentNameForLabels: null,
		shipmentTypeLabels: null,
		shipmentPickupDate: null,
		shipmentOriginAddress: null,
		shipmentDestinationAddress: null,
		shipmentDefaultManualLabel: false,
		adminId: false,
		apiParam: {},
		assignWorkerList: [],
		pagination: {},
		assignModal: false,
		assignRoleName: "worker",
		supervisorStaffId: null,
		apiKeyStatus: false,
		preSetting: {},
		shipmentData: null,
		jobItemLength: 0,
		displayTitle: true,
		company: true,
		contact: true,
		fromAdd: true,
		job: true,
		toAdd: true,
		labelNumber: true,
		qrCode: true,
		qrTitle: "",
		initialTitle: "",
		customerData: null,
		shipmentTagDetails: null,
		originAddress: "",
		destinationAddress: "",
		integrationKeyStatus: false,
		integrationKey: "",
		storageCompanyId: "",
		consumerLoginAccessToken: "",
		warehousesList: [],
		storageShipmentIdForLabels: null,
		isAccountIdRequired: false,
		groupId: null,
		isLoginWithCompanyId: false,
		companyData: [],
		shipmentTypeDataArray: [],
		workerList: [],

	};


	componentDidMount() {
		this.setState({
			companyId:
				(
					(localStorage.getItem("companyID") !== "")
					&&
					(localStorage.getItem("companyID") !== null)
					&&
					(localStorage.getItem("companyID") !== undefined)
				)
					? localStorage.getItem("companyID")
					: null,

			adminId:
				(
					(localStorage.getItem("adminid") !== null)
					&&
					(localStorage.getItem("adminid") !== "")
					&&
					(localStorage.getItem("adminid") !== undefined)
				)
					? true
					: false,

			groupId:
				(
					(localStorage.getItem("groupId") !== null)
					&&
					(localStorage.getItem("groupId") !== "")
					&&
					(localStorage.getItem("groupId") !== undefined)
				)
					? localStorage.getItem("groupId")
					: null,

			isLoginWithCompanyId: localStorage.getItem("isLoginWithCompanyId") ? localStorage.getItem("isLoginWithCompanyId") : false
		},
			() => {
				if (localStorage.getItem("isLoginWithCompanyId") == "true" || localStorage.getItem("isLoginWithCompanyId") == true) {
					localStorage.getItem("loginRoles") === "MAINADMIN" ? this.superAdminCompanyList() : this.groupCompanyList();
				}
			}
		);



		API.get(`api/admin/check/account-id-mandatory`)
			.then((response) => {
				if (response) {
					if (response.status === 1) {
						this.setState({ isAccountIdRequired: response.data && response.data.make_account_id_mandatory == 1 ? true : false, });
					} else {
						this.setState({ contentloader: false });
					}
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});



		API.get(`api/admin/integrationKey/fetch`)
			.then((response) => {
				if (response) {
					if (response.data.status === "active") {
						this.setState({
							integrationKeyStatus: true,
							integrationKey: response.data.integration_key,
							storageCompanyId: response.data.key_company.storage_company_id
						});
						this.consumerLoginJson(response.data.integration_key);
					} else {
						this.setState({
							contentloader: false,
							integrationKeyStatus: false,
						});
					}
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});



		API.get(`api/admin/tag/list/customer`)
			.then((response) => {
				if (response) {
					if (response.status === 1) {
						this.setState({
							tags: response.data,
							contentloader: false,
						});

					} else {
						message.error(response.message);
						this.setState({ contentloader: false });
					}
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});

		API.get(`api/admin/tag/list/shipment`)
			.then((response) => {
				if (response) {
					if (response.status === 1) {
						this.setState({
							tagShipment: response.data,
							contentloader: false,
						});
					} else {
						message.error(response.message);
						this.setState({ contentloader: false });
					}
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});

		const data = [];
		API.get("api/admin/shipment-type/basic/", data)
			.then((response) => {
				if (response) {
					const newData = response.data.filter((data, i) => {
						return data.company_id == this.state.companyId
					})
					this.setState({
						shipmentTypeData: newData,
						contentloader: false,
					});
				} else {
					message.error(response.message);
					this.setState({ contentloader: false });
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});

		let params = {
			search: "",
			orderBy: "created_at",
			pageNo: "1",
			orderSequence: "DESC",
			pageSize: 25,
			company_id:
				(
					(localStorage.getItem("companyID") !== "")
					&&
					(localStorage.getItem("companyID") !== null)
					&&
					(localStorage.getItem("companyID") !== undefined)
				)
					? localStorage.getItem("companyID")
					: null,
		};

		API.get(`api/open-api/apiKey/fetch`)
			.then((response) => {
				if (response) {
					if (response.status === 1) {
						if (response.data.isEnable === 1) {
							this.setState({
								apiKeyStatus: true,
								contentloader: false,
							});
						}
						else {
							this.setState({
								apiKeyStatus: false,
								contentloader: false,
							});
						}

					} else {
						this.setState({ contentloader: false });
					}
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});


	}

	groupCompanyList = () => {
		const groupId = this.state.groupId
		const data = [];
		data["data"] = { group_id: groupId };
		this.setState({ contentloader: true })
		API.post(`api/admin/group-company-list`, data)
			.then((response) => {
				if (response) {
					this.setState({
						companyData: response.data.rows,
						contentloader: false,
					});
				} else {
					this.setState({ contentloader: false });
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});
	}

	superAdminCompanyList = () => {
		this.setState({ contentloader: true })
		API.get(`api/admin/super-admin-company-list`)
			.then((response) => {
				if (response) {
					this.setState({
						companyData: response.data.rows,
						contentloader: false,
					});
				} else {
					this.setState({ contentloader: false });
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});
	}





	consumerLoginJson = (integrationKey) => {
		this.setState({ contentloader: true, loading: true });

		const consumerLoginJson = JSON.stringify({
			companyIdTokenMoverInventory: this.state.integrationKey ? this.state.integrationKey : integrationKey,
			email: "apiconsumer@genie.com",
			password: "5PLaRAqq",
			deviceToken: "abcd",
			deviceType: 0,
		});

		axios.post(`${MOVER_STORAGE_API_URL}login`, consumerLoginJson,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((consumerLoginResponse) => {
				this.fetchCompanyResponse(integrationKey, consumerLoginResponse.data.data.accessToken);

				this.setState({ contentloader: false, loading: false, consumerLoginAccessToken: consumerLoginResponse.data.data.accessToken });
			})
			.catch((error) => {
				this.setState({ contentloader: false, loading: false });
			});
	}

	fetchCompanyResponse = (integrationKey, accessToken) => {
		this.setState({ contentloader: true, loading: true, warehouseLoader: true })

		const fetchCompanyJson = JSON.stringify({
			companyIdTokenMoverInventory: this.state.integrationKey ? this.state.integrationKey : integrationKey,
		});

		axios.post(`${MOVER_STORAGE_API_URL}import/mover-inventory/companies`, fetchCompanyJson,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken ? this.state.consumerLoginAccessToken : accessToken
				}
			})
			.then((fetchCompanyResponse) => {
				this.setState({ warehousesList: fetchCompanyResponse.data.data.warehouses });
				this.setState({ contentloader: false, loading: false, warehouseLoader: false });
			})
			.catch((error) => {
				this.setState({ contentloader: false, loading: false, warehouseLoader: false });
			});
	}

	getShipmentTags = () => {
		const data = [];
		data["data"] = { job_id: this.state.shipmentIdForLabels };
		API.get("api/admin/shipment/" + this.state.shipmentIdForLabels, data)
			.then((response) => {
				if (response) {
					this.setState(
						{
							shipmentTagDetails: response.data && response.data,
							contentloader: false,

						},
					);
				} else {
					message.error(response.message);
					this.setState({ contentloader: false });
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});
	}

	getCustomerTag = () => {
		this.setState({ contentloader: true });
		const data = [];
		data["data"] = { customer_id: this.state.customerIdForShipment };
		API.post("api/admin/customer/view-customer", data)
			.then((response) => {
				if (response) {
					this.setState(
						{
							customerData: response.data && response.data,
							contentloader: false,
						},
					);
				} else {
					this.setState({ contentloader: false });
				}
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});
	}



	fetchAssignWorkerList = () => {
		const data = [];
		data["data"] = this.state.apiParam;
		this.setState({ pageloading: true });
		API.post("api/admin/staff/list/" + this.state.shipmentIdForLabels, data).then((response) => {
			const pagination = { ...this.state.pagination };
			if (response) {
				if (response.status === 1) {
					pagination.total = response.data.count;
					this.setState({
						assignWorkerList: response.data.rows,
						pagination,
						pageloading: false,
					});
				} else {
					this.setState({
						usersList: response.data,
						pagination,
						pageloading: false,
					});
					// message.error(res.message)
				}
			}
		});
	};

	fetchQrCodeSetting = (id) => {
		API.get(`api/admin/qr_code/${id}/get_setting`)
			.then(async (res) => {
				await this.setState({
					preSetting: res.data,
					displayTitle: res.data.title_flag === "yes" ? true : false,
					company: res.data.company_name_flag === "yes" ? true : false,
					contact: res.data.company_contact_flag === "yes" ? true : false,
					fromAdd: res.data.from_address_flag === "yes" ? true : false,
					toAdd: res.data.to_address_flag === "yes" ? true : false,
					qrCode: res.data.qr_code_label_flag === "yes" ? true : false,
					labelNumber: res.data.sequenced_label_flag === "yes" ? true : false,
					job: res.data.job_number_flag === "yes" ? true : false,
					initialTitle:
						(res.data && res.data.place !== null) || res.data.place !== "" ? res.data.place : "",
				});
				this.setState({ contentloader: false });
			})
			.catch((err) => {
				this.setState({ contentloader: false });
			});

		API.get(`api/admin/shipment/${id}`)
			.then(async (response) => {
				if (response) {
					await this.setState({
						shipmentData: response.data && response.data,
						qrTitle:
							this.state.initialTitle !== "" ? this.state.initialTitle : response.data.shipment_name,
						jobItemLength: response.data.job_items.length,
					});
				} else {
					message.error(response.message);
					this.setState({ contentloader: false });
				}
				this.setState({ contentloader: false });
			})
			.catch((error) => {
				this.setState({ contentloader: false });
			});
		setTimeout(() => {
			this.setState({ loading: false });
		}, 120000);
	}




	showDrawer = () => {
		this.componentDidMount();
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	next = () => {
		this.setState({
			current: this.state.current + 1
		})
	};

	prev = () => {
		this.setState({
			current: this.state.current - 1
		})
	};


	addCustomerJson = async (response) => {
		const addCustomerJson = JSON.stringify({
			importCustomerId: response.data.customer_id,
			firstName: (response.data.first_name !== undefined) ? response.data.first_name : "",

			lastName: (response.data.last_name !== undefined) ? response.data.last_name : "",
			address: {
				addressLine1: (response.data.address1 !== undefined) ? response.data.address1 : "",
				addressLine2: (response.data.address2 !== undefined) ? response.data.address2 : "",
				city: (response.data.city !== undefined) ? response.data.city : "",
				state: (response.data.state !== undefined) ? response.data.state : "",
				zipcode: (response.data.zipCode !== undefined) ? response.data.zipCode : "",
				country: (response.data.country !== undefined) ? response.data.country : "",
			},
			email: [
				(response.data.email !== undefined) ? response.data.email : "",
				(response.data.email2 !== undefined) ? response.data.email2 : "",
			],
			phoneNumber: [
				(response.data.phone !== undefined) ? response.data.phone : "",
				(response.data.phone2 !== undefined) ? response.data.phone2 : "",
				(response.data.phone3 !== undefined) ? response.data.phone3 : "",
			],
			accountId: (response.data.account_id !== undefined) ? response.data.account_id : "",
			accountName: (response.data.account_name !== undefined) ? response.data.account_name : "",
			salesRep: (response.data.sales_rep !== undefined) ? response.data.sales_rep : "",
			companyId: this.state.storageCompanyId,
			importedTags: [

			],
			moverInventoryCustomerId: response.data.customer_id,
			createdFromMoverInventory: true
		});

		axios.post(`${MOVER_STORAGE_API_URL}import/mover-inventory/customers`, addCustomerJson,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken
				}
			})
			.then((addCustomerResponse) => {
				this.setState({
					storageCustomerIdForShipment: addCustomerResponse.data.data.id
				})
				let params = {
					customer_id: response.data.customer_id,
					customerIdStorage: addCustomerResponse.data.data.id
				};

				const data = [];
				data["data"] = params;


				API.post("api/admin/customer/storageId/update", data)
					.then((storageIdUpdate) => {
						if (storageIdUpdate.status === 1) {
							this.setState({ loading: false, pageloading: false, contentloader: false });
							message.success(response.message);
							this.next();

						} else {
							this.setState({ loading: false, pageloading: false, contentloader: false });
							message.error(storageIdUpdate.message);
						}
					})
					.catch((error) => {
						message.error(error.message);

						this.setState({ loading: false, pageloading: false, contentloader: false });
					});

			})
			.catch((error) => {
				this.setState({ contentloader: false, loading: false });
			});
	}

	checkCustomerEmailValidation = async (response) => {
		const data = JSON.stringify({
			email: [
				(response.email !== undefined) ? response.email : "",
			],
		})
		const resultData = await axios.post(`${MOVER_STORAGE_API_URL}import/mover-inventory/check-customer-email-already-exist`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken
				}
			})
		return resultData.data.data;
	}


	handleSubmitCustomer = (e) => {
		e.preventDefault();
		let formData = new FormData();
		this.props.form.validateFieldsAndScroll(async (err, values) => {
			if (this.state.integrationKeyStatus) {
				const checkResponse = await this.checkCustomerEmailValidation(values);
				if (checkResponse) {
					this.setState({ loading: false, pageloading: false, contentloader: false });
					message.error("The user email id is associated with another customer in Moverstorage, please add a unique email id");
				}
				else {
					if (!err) {
						const data = [];
						const tag = [];
						if (values.tags) {
							values.tags.forEach((item) => {
								tag.push(item.key);
							});
						}
						formData.append("first_name", values.first_name);
						formData.append("last_name", values.last_name == undefined ? "" : values.last_name);
						formData.append("company_id", this.state.companyId);
						formData.append("phone", values.phone);
						formData.append("email", values.email);
						formData.append("account_id", values.account_id);
						formData.append("tag", JSON.stringify(tag));
						formData.append("password", values.first_name.replace(/\s/g, "") + "@123");

						data["data"] = formData;
						this.setState({ loading: true });
						API.post("api/admin/customer/add", data)
							.then((response) => {
								if (response.status === 1) {
									if (this.state.integrationKeyStatus) {
										this.setState({
											loading: false,
											customerIdForShipment: response.data.customer_id,
											storageCustomerIdForShipment: response.data.storage_customer_id,
											customerNameForShipment: response.data.first_name,
											customerLastNameForShipment: response.data.last_name,
											customerEmailForShipment: response.data.email,
											customerPhoneForShipment: response.data.phone,
											customerAccountIdForShipment: response.data.account_id,
										});
										this.getCustomerTag();
										this.addCustomerJson(response)
									}
									else {
										this.setState({
											loading: false,
											customerIdForShipment: response.data.customer_id,
											customerNameForShipment: response.data.first_name,
											customerLastNameForShipment: response.data.last_name,
											customerEmailForShipment: response.data.email,
											customerPhoneForShipment: response.data.phone,
											customerAccountIdForShipment: response.data.account_id,
										});
										this.getCustomerTag();
										message.success(response.message);
										this.next();
									}
								} else {
									this.setState({ loading: false });
								}
							})
							.catch((error) => {
								this.setState({ loading: false });
							});
					}
				}
			} else {
				if (!err) {
					const data = [];
					const tag = [];
					if (values.tags) {
						values.tags.forEach((item) => {
							tag.push(item.key);
						});
					}
					formData.append("first_name", values.first_name);
					formData.append("last_name", values.last_name == undefined ? "" : values.last_name);
					formData.append("company_id", this.state.companyId);
					formData.append("phone", values.phone);
					formData.append("email", values.email);
					formData.append("account_id", values.account_id);
					formData.append("tag", JSON.stringify(tag));
					formData.append("password", values.first_name.replace(/\s/g, "") + "@123");

					data["data"] = formData;
					this.setState({ loading: true });
					API.post("api/admin/customer/add", data)
						.then((response) => {
							if (response.status === 1) {
								if (this.state.integrationKeyStatus) {
									this.setState({
										loading: false,
										customerIdForShipment: response.data.customer_id,
										storageCustomerIdForShipment: response.data.storage_customer_id,
										customerNameForShipment: response.data.first_name,
										customerLastNameForShipment: response.data.last_name,
										customerEmailForShipment: response.data.email,
										customerPhoneForShipment: response.data.phone,
										customerAccountIdForShipment: response.data.account_id,
									});
									this.getCustomerTag();
									this.addCustomerJson(response)
								}
								else {
									this.setState({
										loading: false,
										customerIdForShipment: response.data.customer_id,
										customerNameForShipment: response.data.first_name,
										customerLastNameForShipment: response.data.last_name,
										customerEmailForShipment: response.data.email,
										customerPhoneForShipment: response.data.phone,
										customerAccountIdForShipment: response.data.account_id,
									});
									this.getCustomerTag();
									message.success(response.message);
									this.next();
								}
							} else {
								this.setState({ loading: false });
							}
						})
						.catch((error) => {
							this.setState({ loading: false });
						});
				}
			}
		});
	};


	editCustomerJson = async (response) => {
		const editCustomerJson = JSON.stringify({
			id: this.state.storageCustomerIdForShipment,
			importCustomerId: this.state.customerIdForShipment,
			firstName: (response.first_name !== undefined) ? response.first_name : "",
			lastName: (response.last_name !== undefined) ? response.last_name : "",
			address: {
				addressLine1: (response.address1 !== undefined) ? response.address1 : "",
				addressLine2: (response.address2 !== undefined) ? response.address2 : "",
				city: (response.city !== undefined) ? response.city : "",
				state: (response.state !== undefined) ? response.state : "",
				zipcode: (response.zipCode !== undefined) ? response.zipCode : "",
				country: (response.country !== undefined) ? response.country : "",
			},
			email: [
				(response.email !== undefined) ? response.email : "",
				(response.email2 !== undefined) ? response.email2 : "",
			],
			phoneNumber: [
				(response.phone !== undefined) ? response.phone : "",
				(response.phone2 !== undefined) ? response.phone2 : "",
				(response.phone3 !== undefined) ? response.phone3 : "",
			],
			accountId: (response.account_id !== undefined) ? response.account_id : "",
			accountName: (response.account_name !== undefined) ? response.account_name : "",
			salesRep: (response.sales_rep !== undefined) ? response.sales_rep : "",
			companyId: this.state.storageCompanyId,
			importedTags: [

			],
		});
		axios.put(`${MOVER_STORAGE_API_URL}import/mover-inventory/customers`, editCustomerJson,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken
				}
			})
			.then((editCustomerResponse) => {
				this.setState({ contentloader: false, loading: false });
				message.success("Customer update successfully.")
				this.next();
			})
			.catch((error) => {
				this.setState({ contentloader: false, loading: false });
			});
	}

	handleEditCustomer = (e) => {
		e.preventDefault();
		let formData = new FormData();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				const data = [];
				const tag = [];
				if (values.tags) {
					values.tags.forEach((item) => {
						tag.push(item.key);
					});
				}
				formData.append("customer_id", this.state.customerIdForShipment);
				formData.append("first_name", values.first_name);
				formData.append("last_name", values.last_name);
				formData.append("company_id", this.state.companyId);
				formData.append("phone", values.phone);
				formData.append("email", values.email);
				formData.append("account_id", values.account_id);
				formData.append("tag", JSON.stringify(tag));
				data["data"] = formData;
				this.setState({ loading: true });

				API.post("api/admin/customer/edit-customer", data)
					.then((response) => {
						if (response) {
							this.setState({
								loading: false,
								customerIdForShipment: this.state.customerIdForShipment,
								customerNameForShipment: values.first_name,
								customerLastNameForShipment: values.last_name,
								customerEmailForShipment: values.email,
								customerPhoneForShipment: values.phone,
								customerAccountIdForShipment: values.account_id,

							});

							if (this.state.integrationKeyStatus && (this.state.storageCustomerIdForShipment !== null && this.storageCustomerIdForShipment !== "" && this.state.storageCustomerIdForShipment !== undefined)) {

								this.editCustomerJson(values)
							}
							else {
								message.success(response.message);
								this.next();
							}

						} else {
							this.setState({ loading: false });
							// message.error(response.message);
						}
					})
					.catch((error) => {
						//console.log("error:", error);
						this.setState({ loading: false });
					});
			}
		});
	};

	addShipmentJson = async (response) => {
		function padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		}

		function formatDate(date) {
			return [
				padTo2Digits(date.getMonth() + 1),
				padTo2Digits(date.getDate()),
				date.getFullYear(),
			].join('-');
		}

		const addShipmentJson = JSON.stringify({
			shipmentName: response.data.shipment_name,
			customerId: this.state.storageCustomerIdForShipment,
			warehouseId: response.data.warehouseId,
			contactReference: response.data.contact_reference,
			accountReference: response.data.account_reference,
			opportunityReference: response.data.opportunity_reference,
			estArrival: formatDate(new Date()),
			estDelivery: formatDate(new Date()),
			workOrderNotes: [

			],
			workOrderReference: response.data.wo_reference,
			externalReference: [

			],
			moveCoord: "",
			source: response.data.source,
			volume: response.data.estimated_volume,
			weight: response.data.estimated_weight,
			estUnits: "",
			originAddress: {
				addressLine1: response.data.pickup_address,
				addressLine2: response.data.pickup_address2,
				city: response.data.pickup_city,
				state: response.data.pickup_state,
				zipcode: response.data.pickup_zipcod,
				country: response.data.pickup_country,

			},
			destinationAddress: {
				addressLine1: response.data.delivery_address,
				addressLine2: response.data.delivery_address2,
				city: response.data.delivery_city,
				state: response.data.delivery_state,
				zipcode: response.data.delivery_zipcode,
				country: response.data.delivery_country,
			},
			importedTags: [],
			moverInventoryShipmentId: response.data.shipment_job_id,
			createdFromMoverInventory: true
		});


		axios.post(`${MOVER_STORAGE_API_URL}import/mover-inventory/shipments`, addShipmentJson,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken
				}
			})
			.then((addShipmentResponse) => {
				this.setState({
					storageShipmentIdForLabels: addShipmentResponse.data.data.id,
				})
				let params = {
					shipment_job_id: response.data.shipment_job_id,
					shipmentIdStorage: addShipmentResponse.data.data.id,
				};
				const data = [];
				data["data"] = params;


				API.post("api/admin/shipment/storageId/update", data)
					.then((storageIdUpdate) => {
						if (storageIdUpdate.status === 1) {
							this.setState({ contentloader: false, loading: false });
							message.success(response.message);
							this.next();
						} else {
							this.setState({ loading: false, contentloader: false });
							message.error(storageIdUpdate.message);
						}
					})
					.catch((error) => {
						message.error(error.message);
						this.setState({ loading: false, contentloader: false });
					});
			})
			.catch((error) => {
				this.setState({ loading: false, contentloader: false });
			});
	}


	handleSubmitShipment = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			const tag = [];
			if (values.shipmentTags) {
				values.shipmentTags.forEach((item) => {
					tag.push(item.key);
				});
			}
			let params = {
				shipment_type_id: values.shipment_type.key,
				shipment_name: values.shipment_name,
				warehouseId: this.state.integrationKeyStatus ? ((values.warehouse_id.key !== undefined) ? values.warehouse_id.key : "") : "",
				email: this.state.customerEmailForShipment,
				company_id: this.state.companyId,
				customer_id: this.state.customerIdForShipment,
				pickup_address: this.state.originAddress,
				delivery_address: this.state.destinationAddress,
				tag: JSON.stringify(tag),
				pickup_date: (values.pickup_date_estimated !== undefined && values.pickup_date_estimated !== "") ? values.pickup_date_estimated : null,
			};
			if (!err || err === null) {
				const data = [];
				data["data"] = params;
				this.setState({ loading: true });
				API.post("api/admin/shipment/", data)
					.then((response) => {
						if (response.status === 1) {
							this.setState({
								shipmentIdForLabels: response.data.shipment_job_id,
								storageShipmentIdForLabels: response.data.storage_shipment_job_id,
								warehouseIdForLabels: response.data.warehouseId,
								shipmentNameForLabels: response.data.shipment_name,
								shipmentTypeLabels: response.data.shipment_type_id,
								shipmentPickupDate: response.data.pickup_date,
								shipmentOriginAddress: response.data.pickup_address,
								shipmentDestinationAddress: response.data.delivery_address,
							});

							this.getShipmentTags();
							this.fetchWorkerList(this.state.companyId)
							this.fetchAssignShipmentTypeStages(response.data.shipment_job_id)

							if (values.Portal_Invite) {

								this.setState({ apiParam: params }, () => this.fetchAssignWorkerList());
								API.post(`api/admin/mail_customer/${response.data.shipment_job_id}`)
									.then((responseMail) => {
										if (responseMail) {
											if (this.state.integrationKeyStatus && response.data.scanIntoStorageCheck) {
												this.addShipmentJson(response);
											}
											else {
												this.setState({ loading: false });
												message.success("Shipment created successfully.");
												this.next();
											}
										} else {
											message.error(response.message);
											this.setState({ loading: false });
										}
									})
							}
							else {
								if (this.state.integrationKeyStatus && response.data.scanIntoStorageCheck) {
									this.setState({ apiParam: params }, () => this.fetchAssignWorkerList());
									this.addShipmentJson(response);
								}
								else {
									this.setState({
										loading: false
									});
									this.setState({ apiParam: params }, () => this.fetchAssignWorkerList());
									this.next();
								}
							}
						} else {
							this.setState({ loading: false });
						}
					})
					.catch((error) => {
						this.setState({ loading: false });
					});
			} else {
				//console.log("inside else");
			}
		});
	};


	editShipmentJson = async (response) => {
		function padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		}
		function formatDate(date) {
			return [
				padTo2Digits(date.getMonth() + 1),
				padTo2Digits(date.getDate()),
				date.getFullYear(),
			].join('-');
		}
		const editShipmentJson = JSON.stringify(
			{
				id: this.state.storageShipmentIdForLabels,
				shipmentName: (response.shipment_name !== undefined) ? response.shipment_name : "",
				customerId: this.state.storageCustomerIdForShipment,
				warehouseId: this.state.warehouseIdForLabels,
				contactReference: (response.contact_reference !== undefined) ? response.contact_reference : "",
				accountReference: (response.account_reference !== undefined) ? response.account_reference : "",
				opportunityTitle: "",
				opportunityReference: (response.opportunity_reference !== undefined) ? response.opportunity_reference : '',
				estArrival: formatDate(new Date()),
				estDelivery: formatDate(new Date()),
				workOrderNotes: [

				],
				workOrderReference: "",
				externalReference: [

				],
				moveCoord: "",
				source: (response.source !== undefined) ? response.source : "",
				volume: (response.estimated_volume !== undefined) ? response.estimated_volume : "",
				weight: (response.estimated_weight !== undefined) ? response.estimated_weight : "",
				estUnits: "",
				originAddress: {
					addressLine1: (response.pickup_address !== undefined) ? response.pickup_address : "",
					addressLine2: (response.pickup_address2 !== undefined) ? response.pickup_address2 : "",
					city: (response.pickup_city !== undefined) ? response.pickup_city : "",
					state: (response.pickup_state !== undefined) ? response.pickup_state : "",
					zipcode: (response.pickup_zipcode !== undefined) ? response.pickup_state : "",
					country: (response.pickup_country !== undefined) ? response.pickup_country : ""
				},
				destinationAddress: {
					addressLine1: (response.delivery_address !== undefined) ? response.delivery_address : "",
					addressLine2: (response.delivery_address2 !== undefined) ? response.delivery_address2 : "",
					city: (response.delivery_city !== undefined) ? response.delivery_city : "",
					state: (response.delivery_state !== undefined) ? response.delivery_city : "",
					zipcode: (response.delivery_zipcode !== undefined) ? response.delivery_zipcode : "",
					country: (response.delivery_country !== undefined) ? response.delivery_country : ""
				},
				importedTags: [
				]
			}
		);


		axios.put(`${MOVER_STORAGE_API_URL}import/mover-inventory/shipments`, editShipmentJson,
			{
				headers: {
					'Content-Type': 'application/json',
					'accessToken': this.state.consumerLoginAccessToken
				}
			})
			.then((editShipmentResponse) => {
				this.setState({ loading: false, contentloader: false });
				message.success("Shipment update successfully.");
				this.next();
			})
			.catch((error) => {
				message.success("Shipment update fail!");
				this.setState({ loading: false, contentloader: false });
			});
	}

	handleEditShipment = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			const tag = [];
			if (values.tags) {
				values.tags.forEach((item) => {
					tag.push(item.key);
				});
			}
			let params = {
				shipment_name: values.shipment_name,
				email: this.state.customerEmailForShipment,
				company_id: this.state.companyId,
				customer_id: this.state.customerIdForShipment,
				pickup_address: this.state.originAddress,
				delivery_address: this.state.destinationAddress,
				tag: JSON.stringify(tag),
			};

			if (!err) {
				const data = [];

				data["data"] = params;
				this.setState({ loading: true });

				API.put("api/admin/shipment/" + this.state.shipmentIdForLabels, data)
					.then((response) => {
						if (response) {
							this.setState({
								loading: false,
								shipmentNameForLabels: values.shipment_name,
								shipmentOriginAddress: values.pickup_address,
								shipmentDestinationAddress: values.delivery_address,
							});
							if (this.state.integrationKeyStatus && (this.state.storageShipmentIdForLabels !== undefined && this.state.storageShipmentIdForLabels !== "" && this.state.storageShipmentIdForLabels !== null)) {
								this.editShipmentJson(params)
							}
							else {
								message.success(response.message);
								this.next();
							}
						} else {
							this.setState({ loading: false });
							// message.error(response.message);
						}
					})
					.catch((error) => {
						this.setState({ loading: false });
					});
			}

		});
	};


	handleQRTitleChange = (e) => {
		this.setState({ qrTitle: e.target.value });
	};



	handleSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((err, values) => {
			let params = {
				place: values.place,
				title_flag: values.display_title,
				company_name_flag: values.display_company_name,
				company_contact_flag: values.display_company_no,
				from_address_flag: values.display_from_add,
				to_address_flag: values.display_to_add,
				job_number_flag: values.display_job_number,
				qr_code_label_flag: values.display_qr_code,
				sequenced_label_flag: values.display_label_no,
				responseType: "blob",
			};
			if (!err) {
				const data = [];

				data["data"] = params;
				this.setState({ loading: true });
				API.post("api/admin/qr_code/" + this.state.shipmentIdForLabels + "/print/", data)
					.then((response) => {
						const pdfBlob = new Blob([response], {
							type: "application/pdf",
						});
						saveAs(pdfBlob, "qrcode.pdf");
						if (response) {
							this.setState({
								loading: false,
								current: 0,
								customerIdForShipment: null,
								customerNameForShipment: null,
								customerEmailForShipment: null,
								shipmentIdForLabels: null,
								shipmentNameForLabels: null,
							});
							message.success("Your Pdf for QR code labels is generated successfully.")
							this.onClose();
							this.props.history.push("/job")

						} else {
							this.setState({ loading: false });
							message.error("Error while creating pdf.");
						}
					})
					.catch((error) => {
						this.setState({ loading: false });
					});
			}
		});
	};

	handleSaveSettings = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			let params = {
				place: values.place,
				title_flag: values.display_title ? "yes" : "no",
				company_name_flag: values.display_company_name ? "yes" : "no",
				company_contact_flag: values.display_company_no ? "yes" : "no",
				from_address_flag: values.display_from_add ? "yes" : "no",
				to_address_flag: values.display_to_add ? "yes" : "no",
				job_number_flag: values.display_job_number ? "yes" : "no",
				qr_code_label_flag: values.display_qr_code ? "yes" : "no",
				sequenced_label_flag: values.display_label_no ? "yes" : "no",
			};
			if (!err) {
				const data = [];

				data["data"] = params;
				this.setState({ saving: true });
				// /qr_code/:jobId/save_setting
				API.post("api/admin/qr_code/" + this.state.shipmentIdForLabels + "/save_setting", data)
					.then((response) => {

						if (response) {
							this.setState({ saving: false });
							message.success(response.message);
						} else {
							this.setState({ saving: false });
							message.error(response.message);
						}
					})
					.catch((error) => {
						this.setState({ saving: false });
					});
			} else {
				//console.log(err);
			}
		});
	};

	handleSubmitQrCode = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			let params = {
				type: 1,
				jobId: this.state.shipmentIdForLabels,
				quantity: values.quantity,
			};

			if (!err) {

				const data = [];
				data["data"] = params;
				this.setState({ loading: true });
				const resolveAfterPromise = new Promise((resolve, reject) => {
					API.post("api/admin/qr_code/generate", data)
						.then((response) => {
							if (response.status === 1) {
								// let paramsData = {
								// 	place: this.state.shipmentNameForLabels,
								// 	title_flag: true,
								// 	company_name_flag: true,
								// 	company_contact_flag: true,
								// 	from_address_flag: true,
								// 	to_address_flag: true,
								// 	job_number_flag: true,
								// 	qr_code_label_flag: true,
								// 	sequenced_label_flag: true,
								// 	responseType: "blob",
								// };
								// const data = [];
								// data["data"] = paramsData;
								// API.post("api/admin/qr_code/" + this.state.shipmentIdForLabels + "/print/", data)
								// 	.then((response) => {
								// 		const pdfBlob = new Blob([response], {
								// 			type: "application/pdf",
								// 		});
								// 		saveAs(pdfBlob, "qrcode.pdf");
								// 		if (response) {
								// 			this.setState({
								// 				loading: false,
								// 				current: 0,
								// 				customerIdForShipment: null,
								// 				customerNameForShipment: null,
								// 				customerEmailForShipment: null,
								// 				shipmentIdForLabels: null,
								// 				shipmentNameForLabels: null,
								// 			});
								// 			message.success("Your Pdf for QR code labels is generated successfully.")
								// 			// this.onClose();
								// 			this.fetchQrCodeSetting(this.state.shipmentIdForLabels);
								// 			this.next();
								// 			// this.props.history.push("/job")

								// 		} else {
								// 			this.setState({ loading: false });
								// 			message.error("Error while creating pdf.");
								// 		}
								// 	})
								// 	.catch((error) => {
								// 		this.setState({ loading: false });
								// 	});
								this.setState({
									loading: false,
								});
								this.fetchQrCodeSetting(this.state.shipmentIdForLabels);
								this.next();
							} else {
								this.setState({ loading: false });
								reject();
							}
						})
						.catch(() => {
							this.setState({ loading: false });
							reject();

						});
				});
			}
		});
	};


	handleChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		let params = {
			...this.state.apiParam,
			pageNo: pagination.current,
			orderBy:
				sorter.field === "createdAt"
					? "created_at"
					: sorter.field === "company.company_name"
						? "company_name"
						: sorter.field,
			// orderTable: sorter.field === "company.company_name" ? "staff_company" : "staff",
			orderSequence: sorter.order === "ascend" ? "ASC" : "DESC",
			pageSize: pagination.pageSize,
		};
		this.setState(
			{
				pagination: pager,
				apiParam: params,
			},
			() => {
				this.state.search ? this.handleSearch(null, this.state.search) : this.fetchAssignWorkerList();
			}
		);
	};


	handleQRLableChange = (label) => {
		if (label === "title") {
			this.setState({ displayTitle: !this.state.displayTitle });
		} else if (label === "job") {
			this.setState({ job: !this.state.job });
		} else if (label === "company") {
			this.setState({ company: !this.state.company });
		} else if (label === "contact") {
			this.setState({ contact: !this.state.contact });
		} else if (label === "from") {
			this.setState({ fromAdd: !this.state.fromAdd });
		} else if (label === "to") {
			this.setState({ toAdd: !this.state.toAdd });
		} else if (label === "label") {
			this.setState({ labelNumber: !this.state.labelNumber });
		} else if (label === "qr") {
			this.setState({ qrCode: !this.state.qrCode });
		}
	};


	handletoggle = (assignRole, unassignRole, assignId, assignWorkerId) => {



		const assignData = [];
		assignData["data"] = {
			staff_id: assignId,
			role: assignRole,
		};
		const assignUnlinkData = [];
		assignUnlinkData["data"] = {
			staff_worker_id: assignWorkerId,
			role: unassignRole,
		};
		API.put("api/admin/shipment/" + this.state.shipmentIdForLabels + "/unlink/", assignUnlinkData).then(
			(response) => {
				if (response) {
					this.fetchAssignWorkerList();
				} else {
					// this.setState({
					// 	loading: false,
					// });
					// message.error(response.message);
				}
			}
		);
		API.put("api/admin/shipment/" + this.state.shipmentIdForLabels + "/link/", assignData).then(
			(response) => {
				if (response) {
					this.fetchAssignWorkerList();
				} else {
					message.error(response.message);
				}
			}
		);
	};

	checkboxHandle = async (assignTitle, assignId, assignValue, assignWorkerId, getRole) => {
		const id = assignId;
		const workerId = assignWorkerId;

		const assignData = [];
		assignData["data"] = {
			staff_id: id,
			role: assignTitle === "AssignSupervisor" ? "supervisor" : "worker",
		};

		const assignUnlinkData = [];
		assignUnlinkData["data"] = {
			staff_worker_id: workerId,
			role: assignTitle === "AssignSupervisor" ? "supervisor" : "worker",
		};

		if (assignValue === 0) {
			API.put("api/admin/shipment/" + this.state.shipmentIdForLabels + "/link/", assignData).then(
				(response) => {
					if (response) {
						this.fetchAssignWorkerList();
					} else {
						message.error(response.message);
					}
				}
			);
		} else if (
			assignTitle === "AssignWorker" &&
			getRole !== "worker" &&
			assignValue === 1 &&
			assignWorkerId !== null
		) {
			this.handletoggle("worker", "supervisor", assignId, assignWorkerId);
		} else if (
			assignTitle === "AssignSupervisor" &&
			getRole !== "supervisor" &&
			assignValue === 1 &&
			assignWorkerId !== null
		) {
			this.handletoggle("supervisor", "worker", assignId, assignWorkerId);
		} else {
			API.put("api/admin/shipment/" + this.state.shipmentIdForLabels + "/unlink/", assignUnlinkData).then(
				(response) => {
					if (response) {
						this.fetchAssignWorkerList();
					} else {
						message.error(response.message);
					}
				}
			);
		}
	};
	callback = () => {
		if (timeoutVar) {
			clearTimeout(timeoutVar);
			timeoutVar = null;
		}
		timeoutVar = setTimeout(this.fetchAssignWorkerList, 1000);
	};

	handleChange = originAddress => {
		this.setState({ originAddress });
	};

	handleSelect = originAddress => {
		this.setState({ originAddress });
	};

	handleChangeDestination = destinationAddress => {
		this.setState({ destinationAddress });
	};

	handleSelectDestination = destinationAddress => {
		this.setState({ destinationAddress });
	};



	handleSearch = (e) => {
		this.setState(
			{
				apiParam: {
					...this.state.apiParam,
					search: e.target.value,
				},
				search: e.target.value,
			},
			this.callback
		);
	};

	padLeadingZeros = (num, size) => {
		let s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	};

	isPickDateMandatoryCheckFunc = (e) => {
		const result = this.state.shipmentTypeData.find(shipmentType => {
			if (shipmentType.shipment_type_id == e.key) {
				return shipmentType;
			}
		});
		if (result.is_pickup_date_mandatory == 1) {
			this.setState({
				isPickupDateMandatoryFlag: true
			})
		}
		else {
			this.setState({
				isPickupDateMandatoryFlag: false
			})
		}
	}

	autocompleteDropdownStyle = {
		position: 'absolute',
		top: '100%',
		left: 0,
		width: '500px',
		zIndex: 1,
		backgroundColor: '#ffffff',
		border: '1px solid #ccc',
		boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
		maxHeight: '200px',
		overflowY: 'auto',
		padding: '5px',
	};

	OnCompanyIDChange = (values) => {
		this.setState({ contentloader: true });
		const { login } = this.props;
		let params = {
			company_id: values.key
		};
		const data = [];
		data["data"] = params;
		API.post("api/admin/signin-with-companyId", data)
			.then((response) => {
				if (response.data && response.data.api_status) {
					localStorage.setItem("apiKeyStatus", response.data.api_status);
				}

				localStorage.setItem("email", response.data.email);
				localStorage.setItem("userType", response.data.userType);
				localStorage.setItem("editShipmentTypeFlagCheck", response.data.staff_id ? response.data.is_admin_can_edit_shipment_type : 1);
				localStorage.setItem("companyEmailForStaff", response.data.staff_id ? response.data["staff_company.email"] : null);
				localStorage.setItem("isLoginWithCompanyId", true)
				localStorage.setItem("groupId", response.data.group_id)

				login({
					access_token: response.data.access_token,
					adminid: response.data.admin_id ? response.data.admin_id : "",
					fname: response.data.first_name ? response.data.first_name : "Admin",
					lname: response.data.last_name ? response.data.last_name : "",
					company: response.data.company_name
						? response.data.company_name
						: response.data["staff_company.company_name"]
							? response.data["staff_company.company_name"]
							: "",
					companyID: response.data.company_id ? response.data.company_id : "",
					staffId: response.data.staff_id ? response.data.staff_id : "",
					pdf_time_stamp_checked: response.data.pdf_time_stamp_checked
						? response.data.pdf_time_stamp_checked
						: response.data["staff_company.pdf_time_stamp_checked"]
							? response.data["staff_company.pdf_time_stamp_checked"]
							: "",
				});
				this.setState({ contentloader: false });
				this.props.history.push({ pathname: "/dashboard" });
			})
			.catch(() => this.setState({ contentloader: false }));
	};

	onShipmentTypeStageChange = (e) => {
		const result = this.state.shipmentTypeDataArray.find(stage => {
			if (stage.local_shipment_stage_id == e.key) {
				return stage;
			}
		});
	}


	handleSubmitWorkerAssignToShipment = (e) => {
		e.preventDefault();
		let formData = new FormData();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				const data = [];
				formData.append("staff_id", (values.worker_id.key !== undefined) ? values.worker_id.key : "");
				formData.append("stage_id", (values.shipment_type_stage.key !== undefined) ? values.shipment_type_stage.key : "");
				formData.append("role", (values.roles !== undefined) ? values.roles : "");
				this.setState({ pageloading: true });

				data["data"] = formData;

				API.post("api/admin/staff/assign-to-shipment/" + this.state.shipmentIdForLabels, data)
					.then((response) => {
						if (response.status === 1) {
							this.setState({ pageloading: false });
							message.success(response.message);
							this.fetchAssignShipmentTypeStages(this.state.shipmentIdForLabels)
							this.fetchAssignWorkerList()
							this.next()
						} else {
							this.setState({ pageloading: false });
							message.error(response.message);
							this.next()
						}
					})
					.catch((error) => {
						this.setState({ pageloading: false });
						this.next()
					});
			}
		});
	};


	fetchWorkerList = (companyId) => {
		const data = [];
		let params = {
			company_id: companyId
		};
		data["data"] = params
		this.setState({ pageloading: true });
		API.post("api/admin/staff/list-shipment-type", data).then((response) => {
			if (response) {
				if (response.status === 1) {
					this.setState({
						workerList: response.data.rows,
						pageloading: false,
					});
				} else {
					this.setState({
						workerList: [],
						pageloading: false,
					});
				}
			}
			else {
				this.setState({
					workerList: [],
					pageloading: false,
				});
			}
		});
	};
	fetchAssignShipmentTypeStages = (jobId) => {
		const data = [];
		data["data"] = { shipmentId: jobId };
		this.setState({ pageloading: true });
		API.post("api/admin/assign-shipment-type-stages", data).then((response) => {
			if (response) {
				if (response.status === 1) {
					this.setState({
						shipmentTypeDataArray: response.data,
						pageloading: false,
					});
				} else {
					this.setState({
						pageloading: false,
					});
				}
			}
		});
	};

	delete(id) {
		this.setState({ deleteModal: true });
		deleteStaffId = id;
	}

	handleCancel = () => {
		this.setState({ deleteModal: false, pageloading: false });
	};
	handleOk = () => {
		const deleteData = [];
		deleteData["data"] = { assignId: deleteStaffId };
		this.setState({ pageloading: true });
		API.post(`api/admin/staff/delete-assign-worker-stage`, deleteData).then((response) => {
			if (response && response.status == 1) {
				this.fetchAssignWorkerList();
				message.success(response.message);
			} else {
				message.error(response.message);
			}
			this.setState({
				deleteModal: false,
				pageloading: false,
			});
		});
	};

	render() {
		const { apiKeyStatus } = this.state
		const { getFieldDecorator } = this.props.form;
		const { shipmentData, jobItemLength, preSetting } = this.state;
		const initialLableNumber = this.padLeadingZeros(jobItemLength, 4);
		const initialShipmentType = this.state.shipmentIdForLabels === "" ? {} : { key: this.state.shipmentTypeLabels };
		const initialWarehouse = this.state.warehouseIdForLabels === "" ? {} : { key: this.state.warehouseIdForLabels };
		const initialPickupDate = this.state.shipmentIdForLabels === "" ? {} : { key: this.state.shipmentPickupDate };
		const initialTag = this.state && this.state.customerData &&
			this.state.customerData.customer_tag.map((item, index) =>
				item.m2m_customer_tag.tag_id === "" ? {} : { key: item.m2m_customer_tag.tag_id }
			);
		const initialTag2 = this.state && this.state.shipmentTagDetails &&
			this.state.shipmentTagDetails.shipment_tag.map((item, index) =>
				item.m2m_tag.tag_id === "" ? {} : { key: item.m2m_tag.tag_id }
			);
		const columns = [
			{
				title: "User Name",
				dataIndex: "full_name",
				key: "full_name",
			},

			{
				title: "User Email",
				dataIndex: "email",
				key: "email",
			},

			{
				title: "User Role",
				dataIndex: "role",
				key: "role",
				align: "left",
				width: 50
			},
			{
				title: "Action",
				key: "action",
				width: 50,
				align: "center",
				render: (record, text) => {
					return (
						<Tooltip title='Delete'>
							<Button
								type='primary'
								className='c-btn c-round c-danger'
								icon='delete'
								onClick={() => this.delete(text.assign_job_worker_id)}></Button>
						</Tooltip>
					);
				},
			},


		];

		const formItemLayout = {
			labelCol: {
				xs: {
					span: 24,
				},
				sm: {
					span: 5,
				},
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: {
					span: 12,
					offset: 1,
				},
				md: {
					span: 12,
					offset: 1,
				},
				lg: {
					span: 12,
					offset: 1,
				},
				xl: {
					span: 10,
					offset: 1,
				},
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 6,
				},
				sm: {
					span: 16,
					offset: 6,
				},
				xl: {
					offset: 6,
				},
			},
		};


		const first_name = localStorage.getItem("fname")
			? localStorage.getItem("fname")
			: "";
		const last_name = localStorage.getItem("lname")
			? localStorage.getItem("lname")
			: "";
		const company_name = localStorage.getItem("company")
			? localStorage.getItem("company")
			: "";
		const { toggleCollapsed } = this.props;
		const collapsed = this.props.collapsed && !this.props.openDrawer;
		const styling = {
			background: customizedTheme.backgroundColor,
			position: "fixed",
			width: "100%",
			height: 70,
		};

		function disabledDate(current) {
			return current && current < moment().endOf('day').subtract(1, 'day');
		}

		return (
			<TopbarWrapper>
				<Header
					style={styling}
					className={
						collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
					}
				>
					<div className="isoLeft">
						<button
							className={
								collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
							}
							style={{ color: customizedTheme.textColor }}
							onClick={toggleCollapsed}
						/>
						{this.state.adminId ? ""
							:
							<>
								{apiKeyStatus ?
									""
									:
									<Button
										style={{ marginLeft: "20px" }}
										onClick={this.showDrawer}
										className='submitButton'
										type='primary'
										htmlType='submit'>
										Quick Create
									</Button>
								}
							</>

						}

					</div>
					{(this.state.isLoginWithCompanyId == "true" || this.state.isLoginWithCompanyId == true) ?
						<Select
							className='isoInputWrapper'
							size={"default"}
							showSearch
							labelInValue
							optionFilterProp='children'
							placeholder='Select Company'
							style={{ width: "20%", marginTop: "19px" }}
							onChange={this.OnCompanyIDChange}
						>
							{this.state.companyData && this.state.companyData.map((e) => (
								<Option key={e.company_id} value={e.company_id}>
									{e.company_identity}
								</Option>
							))}
						</Select>

						: ""
					}

					<ul className="isoRight">
						<li>
							<strong style={{ textTransform: "capitalize", margin: "0" }}>
								{first_name} {last_name}{" "}
								{company_name && first_name ? "-" : "- Super Admin"}{" "}
								{company_name}
							</strong>
						</li>
						<li
							onClick={() => this.setState({ selectedItem: "user" })}
							className="isoUser"
						>
							<TopbarUser />
						</li>
					</ul>

					<Drawer
						title={`${steps[this.state.current].content}`}
						width={1130}
						onClose={this.onClose}
						visible={this.state.visible}
						bodyStyle={{ paddingBottom: 80 }}
						extra={
							<>
								<Button onClick={this.onClose}>Cancel</Button>
								<Button onClick={this.onClose} type="primary">
									Submit
								</Button>
							</>
						}
					>
						<Steps style={{ marginBottom: "10px" }} current={this.state.current}>
							{steps.map(item => (
								<Step key={item.title} title={item.title} />
							))}
						</Steps>
						<div style={{ marginBottom: "10px" }} >
							{this.state.current === 0 ?

								<LayoutContentWrapper style={{ height: "70vh", padding: "0px" }}>
									<div className='add_header'>
										<h2 style={{ marginBottom: "0" }}>
											<i class='fas fa-plus'></i>&emsp; {this.state.customerIdForShipment ? "Edit Customer" : "Add Customer"}
										</h2>
									</div>
									<LayoutContent
										style={{
											margin: "0px",
											height: "93%",
											overflowY: "auto",
										}}>
										<div>
											<Spin spinning={this.state.contentloader} indicator={antIcon}>
												<Form {...formItemLayout} onSubmit={this.state.customerIdForShipment ? this.handleEditCustomer : this.handleSubmitCustomer}>


													<Form.Item label='First Name'>
														{getFieldDecorator("first_name", {
															initialValue: this.state.customerIdForShipment ? this.state.customerNameForShipment : "",
															placeholder: "First Name",
															rules: [
																{
																	required: true,
																	message: "Please input first name!",
																	whitespace: true,
																},
															],
														})(<Input placeholder='First Name' />)}
													</Form.Item>
													<Form.Item label='Last Name'>
														{getFieldDecorator("last_name", {
															initialValue: this.state.customerIdForShipment ? this.state.customerLastNameForShipment : "",

															placeholder: "Last Name",
															rules: [
																{
																	required: true,
																	message: "Please input first name!",
																	whitespace: true,
																},
															],
														})(<Input placeholder='Last Name' />)}
													</Form.Item>

													<Form.Item label='Primary Email'>
														{getFieldDecorator("email", {
															initialValue: this.state.customerIdForShipment ? this.state.customerEmailForShipment : "",
															rules: [
																{
																	type: "email",
																	message: "The input is not valid Email!",
																},
																{
																	required: true,
																	message: "Please input your Email!",
																},
															],
														})(<Input placeholder='Primary Email' />)}
													</Form.Item>
													<Form.Item label='Account ID'>
														{getFieldDecorator("account_id",
															{
																initialValue: this.state.customerIdForShipment ? this.state.customerAccountIdForShipment : "",
																rules: [
																	{
																		required: this.state.isAccountIdRequired ? true : false,
																		message: "Please enter Account ID",
																	},
																],
															}
														)(<Input placeholder='Account ID' />)}
													</Form.Item>
													<Form.Item label='Primary Phone Number'>
														{getFieldDecorator("phone", {
															initialValue: this.state.customerIdForShipment ? this.state.customerPhoneForShipment : "",
														})(
															<Input
																style={{ width: "100%" }}
																placeholder=' Primary Phone Number'
																customInput={Input}
																maxLength={20}
															/>
														)}
													</Form.Item>

													<Form.Item label='Tags'>
														{getFieldDecorator(
															"tags",
															{
																initialValue: this.state.customerIdForShipment && initialTag && initialTag[0] ? initialTag : []

															}
														)(
															<Select
																getPopupContainer={trigger => trigger.parentNode}
																mode='multiple'
																labelInValue
																allowClear
																style={{ width: "100%" }}
																placeholder='Please select tags'>
																{this.state.tags.map((item) => (
																	<Option value={item.tag_id} key={item.tag_id}>
																		<Tag color={item.color ? item.color : ""}>{item.name}</Tag>
																	</Option>
																))}
															</Select>
														)}
													</Form.Item>
													{this.state.customerIdForShipment ? <Form.Item {...tailFormItemLayout}>
														<Button
															className='submitButton'
															loading={this.state.loading}
															type='primary'
															htmlType='submit'>
															Edit Customer
														</Button>
													</Form.Item>
														:

														<Form.Item {...tailFormItemLayout}>
															<Button
																className='submitButton'
																loading={this.state.loading}
																type='primary'
																htmlType='submit'>
																Add Customer
															</Button>
														</Form.Item>
													}

												</Form>
											</Spin>
										</div>
									</LayoutContent>
								</LayoutContentWrapper>
								:
								this.state.current === 1 ?
									<LayoutContentWrapper style={{ height: "80vh", padding: "0px" }}>
										<div className='add_header'>
											<h2 style={{ marginBottom: "0" }}>
												<i class='fas fa-plus'></i>&emsp; {this.state.shipmentIdForLabels ? "Edit Shipment" : "Add Shipment"}
											</h2>
											<button className='backButton' onClick={this.prev}>
												<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
											</button>
										</div>
										<LayoutContent
											style={{
												margin: "0px",
												height: "93%",
												overflowY: "auto",
											}}>
											<div>
												<Spin spinning={this.state.contentloader} indicator={antIcon}>
													<Form {...formItemLayout} onSubmit={this.state.shipmentIdForLabels ? this.handleEditShipment : this.handleSubmitShipment}>


														<Form.Item label='Shipment Type'>
															{getFieldDecorator("shipment_type", {
																initialValue: this.state.shipmentIdForLabels ? initialShipmentType : "",
																rules: [
																	{
																		required: true,
																		message: "Please select shipment type",
																	},
																],
															})(
																<Select
																	size={"default"}
																	labelInValue
																	onChange={(e) => {
																		this.isPickDateMandatoryCheckFunc(e);
																	}}
																	placeholder='Select Shipment Type'
																	style={{ width: "100%" }}>
																	{this.state.shipmentTypeData
																		? this.state.shipmentTypeData.map((e) => (
																			<Option key={e.shipment_type_id} value={e.shipment_type_id}>
																				{e.name}
																			</Option>
																		))
																		: null}
																</Select>
															)}
														</Form.Item>


														{
															this.state.integrationKeyStatus ?
																<Form.Item label='Warehouses'>
																	{getFieldDecorator("warehouse_id", {
																		initialValue: this.state.warehouseIdForLabels ? initialWarehouse : "",

																		rules: [
																			{
																				required: true,
																				message: "Please select warehouse",
																			},
																		],
																	})(
																		<Select
																			size={"default"}
																			labelInValue
																			placeholder='Select warehouse'
																			style={{ width: "100%" }}>
																			{this.state.warehousesList
																				? this.state.warehousesList.map((e) => (
																					<Option key={e.id} value={e.id}>
																						{e.name == "Sample Warehouse" ? e.name + " " + `(${e.companyName})` : e.name}
																					</Option>
																				))
																				: null}
																		</Select>
																	)}
																</Form.Item>
																: ""
														}

														<Form.Item label='Shipment Name'>
															{getFieldDecorator("shipment_name", {
																placeholder: "Shipment Name",
																initialValue: this.state.shipmentIdForLabels ? this.state.shipmentNameForLabels : this.state.customerNameForShipment + " " + "Shipment",

																rules: [
																	{
																		required: true,
																		message: "Please input shipment name!",
																		whitespace: true,
																	},
																],
															})(<Input />)}
														</Form.Item>

														<Form.Item label='Pickup Date'>
															{getFieldDecorator(
																"pickup_date_estimated",
																{
																	initialValue:
																		this.state.shipmentIdForLabels ? this.state && this.state.shipmentPickupDate && moment(this.state.shipmentPickupDate, "YYYY/MM/DD HH:mm:ss") : "",
																	rules: [
																		{
																			required: this.state.isPickupDateMandatoryFlag ? true : false,
																			message: "Please select Pickup date",
																		},
																	],
																},
															)(
																<DatePicker
																	disabledDate={disabledDate}
																	defaultPickerValue={moment()}
																	placeholder='Pickup date'
																/>
															)}
														</Form.Item>

														<Form.Item label='Tags'>
															{getFieldDecorator(
																"shipmentTags",
																{
																	initialValue: this.state.shipmentIdForLabels && initialTag2 && initialTag2[0] ? initialTag2 : [],
																}
															)(
																<Select
																	getPopupContainer={trigger => trigger.parentNode}
																	mode='multiple'
																	labelInValue
																	allowClear
																	style={{ width: "100%" }}
																	placeholder='Please select tags'>
																	{this.state.tagShipment.map((item, index) => (
																		<Option value={item.tag_id} key={item.tag_id}>
																			<Tag color={item.color ? item.color : ""}>{item.name}</Tag>
																		</Option>
																	))}
																</Select>
															)}
														</Form.Item>

														<PlacesAutocomplete
															value={this.state.originAddress}
															onChange={this.handleChange}
															onSelect={this.handleSelect}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<div>
																	<Form.Item label='Origin Address'>
																		<div style={{ position: 'relative' }}>
																			<Input
																				{...getInputProps({
																					placeholder: 'Origin Address ...',
																				})}
																			/>
																			{suggestions.length > 0 && ( // Check if suggestions array has items
																				<div
																					style={this.autocompleteDropdownStyle}
																					className="autocomplete-dropdown-container"
																				>
																					{loading && <div>Loading...</div>}
																					{suggestions.map(suggestion => {
																						const style = {
																							backgroundColor: suggestion.active ? '#f1f2f6' : '#ffffff',
																							cursor: 'pointer',
																						};
																						return (
																							<div
																								key={suggestion.id} // Add a unique key for each suggestion
																								{...getSuggestionItemProps(suggestion, {
																									style,
																								})}
																							>
																								<List.Item>
																									<List.Item.Meta
																										description={suggestion.description}
																									/>
																								</List.Item>
																							</div>
																						);
																					})}
																				</div>
																			)}
																		</div>
																	</Form.Item>
																</div>
															)}
														</PlacesAutocomplete>

														<PlacesAutocomplete
															value={this.state.destinationAddress}
															onChange={this.handleChangeDestination}
															onSelect={this.handleSelectDestination}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<div>
																	<Form.Item label='Destination Address'>
																		<div style={{ position: 'relative' }}>
																			<Input
																				{...getInputProps({
																					placeholder: 'Destination Address ...',
																				})}
																			/>
																			{suggestions.length > 0 && ( // Check if suggestions array has items
																				<div
																					style={this.autocompleteDropdownStyle}
																					className="autocomplete-dropdown-container"
																				>
																					{loading && <div>Loading...</div>}
																					{suggestions.map(suggestion => {
																						const style = {
																							backgroundColor: suggestion.active ? '#f1f2f6' : '#ffffff',
																							cursor: 'pointer',
																						};
																						return (
																							<div
																								key={suggestion.id} // Add a unique key for each suggestion
																								{...getSuggestionItemProps(suggestion, {
																									style,
																								})}
																							>
																								<List.Item>
																									<List.Item.Meta
																										description={suggestion.description}
																									/>
																								</List.Item>
																							</div>
																						);
																					})}
																				</div>
																			)}
																		</div>
																	</Form.Item>

																</div>
															)}
														</PlacesAutocomplete>

														<Form.Item label='Customer Portal Invite'>
															{getFieldDecorator(
																`Portal_Invite`,
															)(<Checkbox>Portal Invite</Checkbox>)}
														</Form.Item>

														{this.state.shipmentIdForLabels ?
															<Form.Item {...tailFormItemLayout}>
																<Button
																	className='submitButton'
																	loading={this.state.loading}
																	type='primary'
																	htmlType='submit'>
																	Edit Shipment
																</Button>
															</Form.Item>
															:



															<Form.Item {...tailFormItemLayout}>
																<Button
																	className='submitButton'
																	loading={this.state.loading}
																	type='primary'
																	htmlType='submit'>
																	Add Shipment
																</Button>
															</Form.Item>

														}

													</Form>
												</Spin>
											</div>
										</LayoutContent>
									</LayoutContentWrapper>
									:
									this.state.current === 2 ?
										<LayoutContentWrapper>
											<div className='top_header' style={{ height: "100%" }}>
												<Row>
													<Col sm={12}>
														<h2 style={{ marginBottom: "0" }}>
															<Icon type='user' />
															&emsp;Assign Worker
														</h2>
													</Col>
													<Col
														sm={12}
														style={{
															textAlign: "right",
														}}>
														<button className='backButton' onClick={this.prev}>
															<i className='fa fa-chevron-left' aria-hidden='true' /> Back
														</button>
													</Col>
												</Row>
											</div>
											<Spin spinning={this.state.contentloader} indicator={antIcon}>
												<LayoutContent>
													<Form {...formItemLayout} onSubmit={this.handleSubmitWorkerAssignToShipment}>

														<Form.Item label='Shipment Type Stage'>
															{getFieldDecorator("shipment_type_stage", {
																rules: [
																	{
																		required: true,
																		message: "Please select shipment type stage",
																	},
																],
															})(
																<Select
																	size={"default"}
																	showSearch
																	labelInValue
																	optionFilterProp='children'
																	onChange={(e) => {
																		this.onShipmentTypeStageChange(e);
																	}}
																	placeholder='Select Shipment Type Stage'
																	style={{ width: "100%" }}>
																	{this.state.shipmentTypeDataArray
																		? this.state.shipmentTypeDataArray.map((e) => (
																			<Option key={e.local_shipment_stage_id} value={e.local_shipment_stage_id}>
																				{e.name}
																			</Option>
																		))
																		: null}
																</Select>
															)}
														</Form.Item>

														<Form.Item label='Select Worker'>
															{getFieldDecorator("worker_id", {
																rules: [
																	{
																		required: true,
																		message: "Please select worker name",
																	},
																],
															})(
																<Select
																	size={"default"}
																	showSearch
																	labelInValue
																	optionFilterProp='children'
																	placeholder='Select worker Name'
																	style={{ width: "100%" }}
																>
																	{this.state.workerList
																		? this.state.workerList.map((e) => (
																			<Option
																				key={e.staff_id}
																				value={e.staff_id}
																			>
																				{e.full_name}
																			</Option>
																		))
																		: null}
																</Select>
															)}
														</Form.Item>

														<Form.Item label='Role'>
															{getFieldDecorator("roles", {
																rules: [{ required: true, message: "Please select role" }],
															})(
																<Select placeholder='Select role' onChange={this.OnChangeSelectUser}>
																	<Option value='supervisor'>Supervisor</Option>
																	<Option value='worker'>Worker</Option>
																</Select>
															)}
														</Form.Item>

														<Form.Item {...tailFormItemLayout}>
															<Button
																className='submitButton'
																loading={this.state.pageloading}
																type='primary'
																htmlType='submit'>
																Submit
															</Button>
														</Form.Item>
													</Form>
												</LayoutContent>
											</Spin>
										</LayoutContentWrapper>
										:
										this.state.current === 3 ?
											<LayoutContentWrapper>
												<div className='top_header' style={{ height: "100%" }}>
													<Row>
														<Col sm={12}>
															<h2 style={{ marginBottom: "0" }}>
																<Icon type='user' />
																&emsp;Worker Management
															</h2>
														</Col>
														<Col
															sm={12}
															style={{
																textAlign: "right",
															}}>
															<button className='backButton' onClick={this.prev}>
																<i className='fa fa-chevron-left' aria-hidden='true' /> Back
															</button>
														</Col>
													</Row>
												</div>
												<Spin spinning={this.state.contentloader} indicator={antIcon}>

													<LayoutContent>
														{this.state.shipmentTypeDataArray.length > 0 && this.state.shipmentTypeDataArray.map((stage, index) =>
														(
															<div key={`table-${index}`} >
																<h2>
																	{stage.name}
																</h2>

																<Table
																	pagination={false}
																	bordered={true}
																	columns={columns}
																	dataSource={this.state.assignWorkerList.filter(data => data.local_shipment_stage_id === stage.local_shipment_stage_id)}
																/>

															</div>
														))}
													</LayoutContent>
												</Spin>
												{
													<Modal
														title='Are You Sure?'
														visible={this.state.deleteModal}
														onOk={this.handleOk}
														okText='Yes'
														cancelText='No'
														centered
														maskClosable={false}
														confirmLoading={this.state.confirmLoading}
														onCancel={this.handleCancel}>
														<p>Are you sure you want to unassign this worker?</p>
													</Modal>
												}
											</LayoutContentWrapper>
											:
											this.state.current === 4 ?
												<LayoutContentWrapper style={{ height: "70vh", padding: "0px" }}>
													<div className='add_header'>
														<h2 style={{ marginBottom: "0" }}>
															<i class='fas fa-plus'></i>&emsp; Create Labels
														</h2>
														<button style={{ marginLeft: "400px" }} className='backButton' onClick={this.prev}>
															<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
														</button>
													</div>
													<LayoutContent
														style={{
															margin: "0px",
															height: "93%",
															overflowY: "auto",
														}}>
														<div>
															<Spin spinning={this.state.contentloader} indicator={antIcon}>
																<Form {...formItemLayout} onSubmit={this.handleSubmitQrCode}>
																	<Form.Item label='Quantity'>
																		{getFieldDecorator("quantity", {
																			placeholder: "Quantity",
																			rules: [

																				{
																					required: true,
																					message: "Please input quantity!",
																				},

																				{
																					min: 1,
																					type: "number",
																					message: "Quantity must be numeric & greater than 0!",
																				},

																				{
																					max: 500,
																					type: "number",
																					message: "Quantity must be numeric & less than or equal to 500!"
																				}
																			],
																		})(<InputNumber placeholder='Quantity' style={{ width: "100%" }} />)}
																	</Form.Item>

																	<Form.Item {...tailFormItemLayout}>
																		<Button
																			className='submitButton'
																			loading={this.state.loading}
																			type='primary'
																			htmlType='submit'>
																			Create Labels
																		</Button>
																	</Form.Item>
																</Form>
															</Spin>
														</div>
													</LayoutContent>
												</LayoutContentWrapper>
												:
												<LayoutContentWrapper style={{ height: "75vh", padding: "0px" }}>
													<div className='add_header'>
														<h2 style={{ marginBottom: "0", marginLeft: "10px" }}>
															<i className='fas fa-qrcode'></i> Print QR Code
														</h2>
														{/* <p>{!this.state.isDone && "Last "  }</p> */}
														<button style={{ marginLeft: "400px" }} className='backButton' onClick={this.prev}>
															<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
														</button>
													</div>
													<LayoutContent
														style={{
															height: "93%",
															overflowY: "auto",
														}}>
														<div className='print-qr'>
															<Row>
																<Col sm={12} style={{ position: "relative" }}>
																	<Form {...formItemLayout} onSubmit={this.handleSubmit}>


																		<Form.Item>
																			{getFieldDecorator("place", {
																				initialValue:
																					preSetting && preSetting.place
																						? preSetting.place
																						: shipmentData && shipmentData.shipment_name,
																				rules: [
																					{
																						required: true,
																						message: "Please input name!",
																					},
																				],
																			})(
																				<Input
																					onChange={(e) => this.handleQRTitleChange(e)}
																					style={{ width: "250px" }}
																					placeholder='Title'
																				/>
																			)}
																		</Form.Item>


																		<Row>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_title", {
																						valuePropName: "checked",
																						initialValue: this.state.displayTitle,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("title")}>Display title</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_job_number", {
																						valuePropName: "checked",
																						initialValue: this.state.job,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("job")}>
																							Display Job Number
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																		</Row>
																		<Row>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_company_name", {
																						valuePropName: "checked",
																						initialValue: this.state.company,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("company")}>
																							Display Company Name
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_company_no", {
																						valuePropName: "checked",
																						initialValue: this.state.contact,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("contact")}>
																							Display Company Phone
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																		</Row>
																		<Row>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_from_add", {
																						valuePropName: "checked",
																						initialValue: this.state.fromAdd,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("from")}>
																							Display Origin Address
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_to_add", {
																						valuePropName: "checked",
																						initialValue: this.state.toAdd,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("to")}>
																							Display Destination Address
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																		</Row>
																		<Row>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_label_no", {
																						valuePropName: "checked",
																						initialValue: this.state.labelNumber,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("label")}>
																							Display Label Number
																						</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																			<Col sm={12}>
																				<Form.Item label=''>
																					{getFieldDecorator("display_qr_code", {
																						valuePropName: "checked",
																						initialValue: this.state.qrCode,
																					})(
																						<Checkbox onChange={() => this.handleQRLableChange("qr")}>Display QR Code</Checkbox>
																					)}
																				</Form.Item>
																			</Col>
																		</Row>

																		<Form.Item
																			{...tailFormItemLayout}
																			style={{
																				marginLeft: "0",
																				marginRight: "0",
																				transform: "translatex(-25%)",
																			}}>
																			<Button
																				className='submitButton'
																				loading={this.state.loading}
																				type='primary'
																				htmlType='submit'>
																				Print
																			</Button>
																		</Form.Item>
																	</Form>
																	<Form
																		style={{
																			position: "absolute",
																			bottom: "0",
																			right: "0",
																			transform: "translatex(-100%)",
																		}}
																		onSubmit={this.handleSaveSettings}>
																		<Form.Item {...tailFormItemLayout}>
																			<Button
																				className='submitButton'
																				loading={this.state.saving}
																				type='primary'
																				htmlType='submit'>
																				Save Settings
																			</Button>
																		</Form.Item>
																	</Form>
																</Col>
																<Col sm={{ span: 10, offset: 1 }}>
																	{this.state.displayTitle && (
																		<div
																			className='QR-title'
																			style={{
																				marginLeft: "15px",
																				fontSize: "20px",
																				fontWeight: "bold",
																			}}>
																			{this.state.qrTitle}
																		</div>
																	)}
																	<div className='isoLogoWrapper'>
																		<div
																			className='QR-image'
																			style={{
																				display: "flex",
																				alignItems: "center",
																			}}>
																			<div style={{ display: "flex", flexDirection: "column" }}>
																				<b
																					style={
																						!this.state.labelNumber ? { visibility: "hidden" } : { visibility: "visible" }
																					}>
																					<h2 style={{ margin: "0px 0px 0px 30px" }} >	{initialLableNumber ? initialLableNumber : "0000"} </h2>
																				</b>
																				<img src={Sample_Qr} style={{ marginTop: "-5px" }} width='135px' alt='Mover Inventory' />
																				<div style={{ margin: " -15px 0px 0px 18px" }}>
																					<h2> <b> {this.state.qrCode && "78QE8901"} </b></h2>
																				</div>
																			</div>
																			<div>
																				{this.state.job && (
																					<div className='QR-job-no'>Job No : {shipmentData && shipmentData.job_number}</div>
																				)}
																				{this.state.company && (
																					<div className='QR-company-name'>
																						{shipmentData && shipmentData.job_company && shipmentData.job_company.company_name}
																					</div>
																				)}
																				{this.state.contact && (
																					<div className='QR-company-contact'>
																						<NumberFormat
																							value={shipmentData && shipmentData.job_company && shipmentData.job_company.phone}
																							displayType={"text"}
																							format='(###) ###-####'
																						/>
																					</div>
																				)}
																				{this.state.fromAdd && (
																					<div className='QR-from-add'>
																						<p>
																							<strong>From: </strong>
																							{shipmentData &&
																								shipmentData.pickup_address &&
																								shipmentData.pickup_address !== "undefined" &&
																								shipmentData.pickup_address !== "null"
																								? shipmentData.pickup_address
																								: ""}
																							{shipmentData &&
																								shipmentData.pickup_address2 &&
																								shipmentData.pickup_address2 !== "undefined" &&
																								shipmentData.pickup_address2 !== "null"
																								? " " + shipmentData.pickup_address2
																								: ""}
																						</p>
																						<p>
																							{shipmentData &&
																								shipmentData.pickup_city &&
																								shipmentData.pickup_city !== "undefined" &&
																								shipmentData.pickup_city !== "null"
																								? shipmentData.pickup_city
																								: ""}
																							{shipmentData &&
																								shipmentData.pickup_state &&
																								shipmentData.pickup_state !== "undefined" &&
																								shipmentData.pickup_state !== "null"
																								? " " + shipmentData.pickup_state
																								: ""}
																							{shipmentData &&
																								shipmentData.pickup_zipcode &&
																								shipmentData.pickup_zipcode !== "undefined" &&
																								shipmentData.pickup_zipcode !== "null"
																								? ", " + shipmentData.pickup_zipcode
																								: ""}
																						</p>
																						<p>
																							{shipmentData &&
																								shipmentData.pickup_country &&
																								shipmentData.pickup_country !== "undefined" &&
																								shipmentData.pickup_country !== "null"
																								? shipmentData.pickup_country
																								: ""}
																						</p>
																					</div>
																				)}
																				{this.state.toAdd && (
																					<div className='QR-to-add'>
																						<p>
																							<strong>To: </strong>
																							{shipmentData &&
																								shipmentData.delivery_address &&
																								shipmentData.delivery_address !== "undefined" &&
																								shipmentData.delivery_address !== "null"
																								? shipmentData.delivery_address
																								: ""}
																							{shipmentData &&
																								shipmentData.delivery_address2 &&
																								shipmentData.delivery_address2 !== "undefined" &&
																								shipmentData.delivery_address2 !== "null"
																								? " " + shipmentData.delivery_address2
																								: ""}
																						</p>
																						<p>
																							{shipmentData &&
																								shipmentData.delivery_city &&
																								shipmentData.delivery_city !== "undefined" &&
																								shipmentData.delivery_city !== "null"
																								? shipmentData.delivery_city
																								: ""}
																							{shipmentData &&
																								shipmentData.delivery_state &&
																								shipmentData.delivery_state !== "undefined" &&
																								shipmentData.delivery_state !== "null"
																								? " " + shipmentData.delivery_state
																								: ""}
																							{shipmentData &&
																								shipmentData.delivery_zipcode &&
																								shipmentData.delivery_zipcode !== "undefined" &&
																								shipmentData.delivery_zipcode !== "null"
																								? ", " + shipmentData.delivery_zipcode
																								: ""}
																						</p>
																						<p>
																							{shipmentData &&
																								shipmentData.delivery_country &&
																								shipmentData.delivery_country !== "undefined" &&
																								shipmentData.delivery_country !== "null"
																								? shipmentData.delivery_country
																								: ""}
																						</p>
																					</div>
																				)}
																			</div>
																		</div>
																		<div
																			className='QR-details'
																			style={{
																				marginLeft: "15px",
																				marginTop: "12px",
																				width: "60%",
																			}}>
																			{/* {this.state.fromAdd && (
													<div className="QR-from-add">
														<p>
															<strong>From: </strong>
															{shipmentData &&
																shipmentData.pickup_address &&
																shipmentData.pickup_address !== "undefined" &&
																shipmentData.pickup_address !== "null"
																? shipmentData.pickup_address
																: ""}
															{shipmentData &&
																shipmentData.pickup_address2 &&
																shipmentData.pickup_address2 !== "undefined" &&
																shipmentData.pickup_address2 !== "null"
																? " " + shipmentData.pickup_address2
																: ""}
														</p>
														<p>
															{shipmentData &&
																shipmentData.pickup_city &&
																shipmentData.pickup_city !== "undefined" &&
																shipmentData.pickup_city !== "null"
																? shipmentData.pickup_city
																: ""}
															{shipmentData &&
																shipmentData.pickup_state &&
																shipmentData.pickup_state !== "undefined" &&
																shipmentData.pickup_state !== "null"
																? " " + shipmentData.pickup_state
																: ""}
															{shipmentData &&
																shipmentData.pickup_zipcode &&
																shipmentData.pickup_zipcode !== "undefined" &&
																shipmentData.pickup_zipcode !== "null"
																? ", " + shipmentData.pickup_zipcode
																: ""}
														</p>
														<p>
															{shipmentData &&
																shipmentData.pickup_country &&
																shipmentData.pickup_country !== "undefined" &&
																shipmentData.pickup_country !== "null"
																? shipmentData.pickup_country
																: ""}
														</p>
													</div>
												)}
												{this.state.toAdd && (
													<div className="QR-to-add">
														<p>
															<strong>To: </strong>
															{shipmentData &&
																shipmentData.delivery_address &&
																shipmentData.delivery_address !== "undefined" &&
																shipmentData.delivery_address !== "null"
																? shipmentData.delivery_address
																: ""}
															{shipmentData &&
																shipmentData.delivery_address2 &&
																shipmentData.delivery_address2 !== "undefined" &&
																shipmentData.delivery_address2 !== "null"
																? " " + shipmentData.delivery_address2
																: ""}
														</p>
														<p>
															{shipmentData &&
																shipmentData.delivery_city &&
																shipmentData.delivery_city !== "undefined" &&
																shipmentData.delivery_city !== "null"
																? shipmentData.delivery_city
																: ""}
															{shipmentData &&
																shipmentData.delivery_state &&
																shipmentData.delivery_state !== "undefined" &&
																shipmentData.delivery_state !== "null"
																? " " + shipmentData.delivery_state
																: ""}
															{shipmentData &&
																shipmentData.delivery_zipcode &&
																shipmentData.delivery_zipcode !== "undefined" &&
																shipmentData.delivery_zipcode !== "null"
																? ", " + shipmentData.delivery_zipcode
																: ""}
														</p>
														<p>
															{shipmentData &&
																shipmentData.delivery_country &&
																shipmentData.delivery_country !== "undefined" &&
																shipmentData.delivery_country !== "null"
																? shipmentData.delivery_country
																: ""}
														</p>
													</div>
												)} */}
																		</div>
																	</div>
																</Col>
															</Row>
														</div>
													</LayoutContent>
												</LayoutContentWrapper>

							}

						</div>

						<div style={{ marginTop: "10px" }} className="steps-action">
							{this.state.current === 2 || this.state.current === 3 ?
								<Button type="primary" onClick={this.next}>
									Next
								</Button>
								: ""
							}
							{/* {this.state.current === steps.length - 1 && (
								<Button type="primary" onClick={() => message.success('Processing complete!')}>
									Done
								</Button>
							)} */}
						</div>

					</Drawer>

				</Header>
			</TopbarWrapper >
		);
	}
}

export default Form.create()(connect(
	(state) => ({
		...state.App,
	}),
	{ toggleCollapsed, login }
)(withRouter(Topbar)));


